
import { computed, ref } from 'vue'
import { CourseSeries, createCourseSeries } from 'momai'
import { add } from '@/api/entity/course-series'
import { Notify } from 'vant'
import { fetchCourseSeries } from '@/states/couseSeries'
import { goBack } from '@/states/layout'
import CourseSeriesForm from '../Form.vue'

export default {
  components: {
    CourseSeriesForm
  },
  setup: () => {
    const newCourseSeries = ref<CourseSeries>(createCourseSeries())
    const canNotSave = computed(() => {
      const c = newCourseSeries.value
      return c.courseName === '' || c.img === '' || c.sort === 0 || c.tag === ''
    })
    const fileList = ref([])
    return {
      newCourseSeries,
      fileList,
      canNotSave,
      saveCourseSeries: () => {
        const form = newCourseSeries.value
        add(form).then(() => {
          fetchCourseSeries()
          goBack()
          Notify({
            type: 'success',
            message: '课程系列添加成功'
          })
        })
      }
    }
  }
}
